export type MemberField = {
  key: MemberFieldKey;
  isRequired: boolean;
  isEditable: boolean;
  isLocked: boolean;
  isUserField: boolean;
};

export enum MemberFieldKey {
  Firstname = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  Status = 'status',
  Role = 'role',
  Address = 'address',
  Birthday = 'birthday',
  Department = 'departmentId',
  EmployeeId = 'employeeId',
  Group = 'groupId',
  OfficeLocation = 'officeLocation',
  Position = 'positionId',
  PhoneNumber = 'phoneNumber',
  StartDate = 'startDate',
  BuilderAccess = 'builderAccess',
}

export const defaultMemberFields: MemberField[] = [
  { key: MemberFieldKey.Firstname, isRequired: true, isEditable: true, isLocked: true, isUserField: true },
  { key: MemberFieldKey.LastName, isRequired: true, isEditable: true, isLocked: true, isUserField: true },
  { key: MemberFieldKey.Email, isRequired: true, isEditable: true, isLocked: true, isUserField: true },
  { key: MemberFieldKey.Status, isRequired: true, isEditable: false, isLocked: true, isUserField: true },
  { key: MemberFieldKey.BuilderAccess, isRequired: true, isEditable: true, isLocked: true, isUserField: true },
  { key: MemberFieldKey.Role, isRequired: true, isEditable: true, isLocked: true, isUserField: true },
  { key: MemberFieldKey.Address, isRequired: false, isEditable: true, isLocked: false, isUserField: true },
  { key: MemberFieldKey.Birthday, isRequired: false, isEditable: true, isLocked: false, isUserField: true },
  { key: MemberFieldKey.Department, isRequired: false, isEditable: true, isLocked: false, isUserField: false },
  { key: MemberFieldKey.EmployeeId, isRequired: false, isEditable: true, isLocked: false, isUserField: false },
  { key: MemberFieldKey.Group, isRequired: false, isEditable: true, isLocked: false, isUserField: false },
  { key: MemberFieldKey.OfficeLocation, isRequired: false, isEditable: true, isLocked: false, isUserField: false },
  { key: MemberFieldKey.Position, isRequired: false, isEditable: true, isLocked: false, isUserField: false },
  { key: MemberFieldKey.PhoneNumber, isRequired: false, isEditable: true, isLocked: false, isUserField: true },
  { key: MemberFieldKey.StartDate, isRequired: false, isEditable: true, isLocked: false, isUserField: false },
];

export type DefaultMemberFieldKeys = 'profileImage' | (typeof defaultMemberFields)[number]['key'];
